<template>
    <Modal v-model:visible="visible" class="address-modal" :form="true">
        <template #header>{{ $t(model.id ? 'Edit address' : 'Add a new address') }}</template>

        <div class="flex flex-col gap-4" @click="resetValidator">
            <p v-if="$v.$error" class="error">{{ $t('Fill in all required fields') }}</p>
            <p v-else>{{ $t(model.id ? 'Make changes' : 'Fill in the details') }}</p>

            <Loader v-if="loading" />

            <template v-else>
                <DropdownSearch
                    v-if="countries"
                    v-model="form.countryId"
                    :options="countries"
                    option-label="name"
                    option-value="id"
                    :placeholder="`${$t('Choose country')}*`"
                    :errors="getError('countryId')"
                    @update:model-value="updateCountry"
                />

                <InputText
                    v-model="form.postCode"
                    :is-success="!!form.postCode"
                    :placeholder="postCodePlaceholder"
                    :errors="getError('postCode')"
                />

                <DropdownSearch
                    v-if="regions && !!regions.length"
                    v-model="form.regionId"
                    :options="regions"
                    option-label="name"
                    option-value="id"
                    :placeholder="`${$t('Choose region')}*`"
                    :errors="getError('regionId')"
                    @update:model-value="updateRegion"
                />

                <InputText
                    v-if="!regions?.length"
                    v-model="form.region"
                    :is-success="!!form.region"
                    :placeholder="`${$t('Enter region')}`"
                    :disabled="regions === null"
                    :errors="getError('region')"
                />

                <DropdownSearch
                    v-if="form.regionId"
                    v-model="form.areaId"
                    :options="areas || []"
                    option-label="name"
                    option-value="id"
                    :placeholder="`${$t('Choose area')}*`"
                    :disabled="!areas?.length"
                    :errors="getError('areaId')"
                />

                <InputText
                    v-if="!form.regionId"
                    v-model="form.area"
                    :is-success="!!form.area"
                    :placeholder="`${$t('Enter area')}*`"
                    :disabled="regions === null || (!!regions.length && !form.regionId)"
                    :errors="getError('area')"
                />

                <InputText
                    v-model="form.city"
                    :is-success="!!form.city"
                    :placeholder="`${$t('Enter a city')}*`"
                    :errors="getError('city')"
                />

                <InputText
                    v-model="form.street"
                    :is-success="!!form.street"
                    :placeholder="`${$t('Enter a street')}*`"
                    :errors="getError('street')"
                />

                <InputText
                    v-model="form.house"
                    :is-success="!!form.house"
                    :placeholder="`${$t('Enter a house')}*`"
                    :errors="getError('house')"
                />

                <InputText
                    v-model="form.building"
                    :is-success="!!form.building"
                    :placeholder="$t('Enter a building number')"
                />

                <InputText
                    v-model="form.apartment"
                    :is-success="!!form.apartment"
                    :placeholder="`${$t('Enter a office/apartment number')}`"
                />
            </template>

            <div class="flex gap-4 mt-2">
                <Button
                    :type="EBtnType.TERTIARY"
                    :size="ESize.DEFAULT"
                    :value="$t('Cancel')"
                    @click.stop="visible = false"
                />

                <Button
                    :size="ESize.DEFAULT"
                    :value="$t(form.id ? 'Edit address' : 'Add address')"
                    @click.stop="submit"
                />
            </div>
        </div>
    </Modal>
</template>

<script setup lang="ts">
import type {
    IShipmentAddress,
    IShipmentArea,
    IShipmentCountry,
    IShipmentRegion,
} from '@ts/interfaces/orders.interfaces';
import type { NumberOrNull, StringOrNull } from '@ts/types/app.type';
import { EBtnType, ESeverityType, ESize } from '@ts/enums/app.enum';
import { useVuelidate } from '@vuelidate/core';
import { helpers, required } from '@vuelidate/validators';
import { getAreas, getCountries, getRegions } from '@/api/purchasing-logistics/addresses';
import DropdownSearch from '@c/DropdownSearch.vue';
import Modal from '@c/Modal.vue';
import Loader from '@c/Loader.vue';
import InputText from '@c/InputText.vue';
import Button from '@c/Button.vue';

/** Interfaces **/
interface Props {
    model: IShipmentAddress;
}

/** Props **/
const visible = defineModel<boolean>('visible', { required: true, default: false });

const props = defineProps<Props>();

/** Emits **/
const emit = defineEmits(['submit']);

/** Data **/
const { t } = useI18n();
const toast = useToast();
const form = ref<IShipmentAddress>(props.model);
const loading = ref<boolean>(true);
const countries = ref<IShipmentCountry[] | null>(null);
const regions = ref<IShipmentRegion[] | null>(null);
const areas = ref<IShipmentArea[] | null>(null);

/** Computed **/
const postCodePlaceholder = computed<string>(
    () => `${t('Enter postal code')}${toValue(form).countryId === 185 ? '' : '*'}`,
);

/** Mounted **/
onMounted(async (): Promise<void> => {
    await setCountries();

    if (toValue(form).countryId) {
        await setRegions(toValue(form).countryId as number);
    }

    if (toValue(form).regionId) {
        await setAreas(toValue(form).regionId as number);
    }

    loading.value = false;
});

/** Methods **/
const setCountries = async (): Promise<void> => {
    const { ok, message, data } = await getCountries();
    if (!ok) {
        openErrorMessage(message);
    }

    countries.value = ok ? data : [];
};

const updateCountry = async (id: number): Promise<void> => {
    regions.value = null;
    areas.value = null;
    form.value = {
        id: props.model.id,
        supplierId: props.model.supplierId,
        countryId: id,
        countryModel: null,
        postCode: null,
        regionId: null,
        region: null,
        regionModel: null,
        areaId: null,
        area: null,
        areaModel: null,
        city: null,
        street: null,
        house: null,
        building: null,
        apartment: null,
    };

    await setRegions(id);
};

const setRegions = async (countryId: number): Promise<void> => {
    const { ok, message, data } = await getRegions(countryId);
    if (!ok) {
        openErrorMessage(message);
    }
    regions.value = ok ? data : [];
};

const updateRegion = async (id: number): Promise<void> => {
    areas.value = null;
    form.value = {
        id: props.model.id,
        supplierId: props.model.supplierId,
        countryId: toValue(form).countryId,
        countryModel: null,
        postCode: toValue(form).postCode,
        regionId: id,
        region: null,
        regionModel: null,
        areaId: null,
        area: null,
        areaModel: null,
        city: null,
        street: null,
        house: null,
        building: null,
        apartment: null,
    };

    await setAreas(id);
};

const setAreas = async (regionId: number): Promise<void> => {
    const { ok, message, data } = await getAreas(regionId);
    if (!ok) {
        openErrorMessage(message);
    }
    areas.value = ok ? data : [];
};

const submit = (): void => {
    toValue($v).$touch();

    if (toValue($v).$error) {
        return;
    }

    resetValidator();
    emit('submit', toValue(form));
};

const resetValidator = (): void => {
    toValue($v).$reset();
};

const getError = (fieldName: string): string[] => {
    return toValue($v)[fieldName]?.$errors?.map((e: any) => e.$message);
};

const openErrorMessage = (message: string): void => {
    toast.add({
        severity: ESeverityType.ERROR,
        summary: t('Error'),
        detail: message || '',
        group: 'notification',
        life: 3000,
    });
};

/** Validate **/
const postCodeValidate = (value: StringOrNull, vm: IShipmentAddress): boolean =>
    vm.countryId === 185 ? !!value : true;

const regionValidate = (value: StringOrNull, vm: IShipmentAddress): boolean =>
    !vm.countryId
        ? false
        : toValue(regions)?.length
          ? true
          : vm.regionId
            ? true
            : vm.countryId === 185
              ? !!value
              : true;

const regionIdValidate = (value: NumberOrNull, vm: IShipmentAddress): boolean =>
    !vm.countryId ? true : vm.region ? true : vm.countryId === 185 ? !!value : true;

const areaValidate = (value: StringOrNull, vm: IShipmentAddress): boolean =>
    !vm.countryId ? false : toValue(areas)?.length ? true : !!value;

const areaIdValidate = (value: NumberOrNull): boolean =>
    !toValue(areas) ? true : !toValue(regions)?.length ? true : !!value;

const rules = {
    countryId: {
        required: helpers.withMessage(t('Required field'), required),
    },
    postCode: {
        postCodeValidate: helpers.withMessage(t('Required field'), postCodeValidate),
    },
    region: {
        regionValidate: helpers.withMessage(t('Required field'), regionValidate),
    },
    regionId: {
        regionIdValidate: helpers.withMessage(t('Required field'), regionIdValidate),
    },
    area: {
        areaValidate: helpers.withMessage(t('Required field'), areaValidate),
    },
    areaId: {
        areaIdValidate: helpers.withMessage(t('Required field'), areaIdValidate),
    },
    city: {
        required: helpers.withMessage(t('Required field'), required),
    },
    street: {
        required: helpers.withMessage(t('Required field'), required),
    },
    house: {
        required: helpers.withMessage(t('Required field'), required),
    },
};

const $v = useVuelidate(rules, form, { $stopPropagation: true });
</script>

<style lang="scss">
.address-modal {
    width: 500px;
}
</style>

<style scoped lang="scss">
@use "assets/styles/variables" as *;

.error {
    color: $color-error-default;
    font-size: $size-14;
    font-weight: $weight-500;
    line-height: $line-height-140;
}
</style>
