import type { IShipmentAddress } from '@ts/interfaces/orders.interfaces';
import type { IResponse } from '@ts/interfaces/api.interfaces';
import api from '@/api.queue.instance';

export const getCountries = async (): Promise<IResponse> => (await api.get('countries')).data;

export const getRegions = async (countryId: number): Promise<IResponse> =>
    (await api.get('regions', { params: { countryId } })).data;

export const getAreas = async (regionId: number): Promise<IResponse> =>
    (await api.get('areas', { params: { regionId } })).data;

export const getAddresses = async (): Promise<IResponse> => (await api.get('delivery-addresses')).data;

export const createOrUpdateAddress = async (data: IShipmentAddress): Promise<IResponse> =>
    (await api.post('delivery-addresses', { data })).data;

export const deleteAddress = async (id: number): Promise<IResponse> =>
    (await api.post('delivery-addresses/delete', { data: { id } })).data;
